@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body {
  margin: 0;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css"); */

* {
  /* background-color: rgb(240, 234, 234); */
  font-family: poppins;
  max-width: 100%;

  /* display: block; */
  /* width: 100%; */
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}
.fixer {
  /* overflow: hidden; */

  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  box-shadow: 0 4px 19px 0 rgba(0, 0, 0, 0.06);
  z-index: 500;
}
.body {
  overflow-x: hidden;
}
/* Navbar Start */
.nav-links li {
  list-style-type: none;
}

.nav-links li a {
  text-decoration: none;
}
/* .navLink:hover{
   color: rgb(192, 189, 189) !important;
} */

.navbar-header {
  display: flex;
  width: 1050px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  color: #042657;
  padding-top: 1%;
  padding-bottom: 1%;
}

.navbar-header-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar-ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: #042657 !important;
}

.navbar-ul a {
  text-decoration: none;
  color: #042657 !important;
}
.navbar-ul a:hover {
  text-decoration: none;
  color: #fd581f !important;
}
.youth {
  width: 15%;
}
.navbar-header-button {
  color: white !important;
  padding: 7px 17px;
  border: none;
  border-radius: 5px;
  background-color: #6543b1;
  text-decoration: none !important;
}
.navbar-header-button:hover {
  text-decoration: none !important;
}

/* .youth{
  text-align: center;
  font-weight: bold;
  line-height: 0;
} */
/* /* 
.nav-links>a{
  font-size: 18px;
  font-weight: 500;
  /* padding: 10px 15px; */
/* } */

.post {
  background-color: #6543b1;
  padding: 10px 15px;
  color: white;
  margin-top: -7%;
  margin-left: 5%;
  width: 100%;
}

.post ::after {
  position: absolute;
  content: '';
  height: 2px;
  background-color: white;
  width: 3%;
  margin-left: 8px;
  margin-top: 1%;
}
.postap {
  color: white;
  background-color: #6543b1;
  padding: 7px 17px;
  text-decoration: none;
  border: none;
  border-radius: 5px;
}
.navbar-header-mobile {
  display: none;
}

.ham {
  display: none;
  cursor: pointer;
}

.ham svg {
  height: 15px !important;
  width: 25px !important;
  margin-left: 10px;
}

@media (max-width: 1100px) {
  .ham {
    display: block;
  }
  .navbar-header-mobile {
    display: block;
  }
  .navbar-header {
    display: none;
  }

  .nav-links {
    position: absolute;
    display: block;
    top: 100%;
    color: white;
    transform: translateX(-100%);
    transition: 0.3s;
  }
  .nav-links li {
    color: white;
    padding-top: 50px;
    width: 75%;
  }
  .nav-links a:hover {
    text-decoration: underline;
    transition: 0.4s;
  }
}

@media (max-width: 769px) {
  .Last-div {
    display: flex;
    flex-direction: column;
  }
  .postap {
    width: 60%;
    margin-left: -15px;
  }

  .find {
    /* margin-left: 6%; */
  }
  .post {
    margin-left: -6%;
  }
  .post ::after {
    display: none;
  }
}
/* .nav{
 display: flex;
} */

/* NavBar ends */
.last {
  width: 100%;
  float: left;
  margin-top: 140px;
}

.Last-div {
  display: flex;
}

.last1 {
  margin-top: 50px;
  margin-left: 40px;
  width: 25%;
}
.last1 img {
  width: 70%;
  margin-left: -5%;
}
.last1 h3 {
}
.last1 h2,
.last2 h2,
.last3 h2,
.last4 h2 {
  font-weight: 500;
  color: #042657;
  font-size: 20px;
}
.last2 {
  margin-top: 50px;
  width: 20%;
  font-weight: lighter;
  margin-left: 50px;
  font-size: 14px;
}
.last3 {
  margin-top: 50px;
  width: 20%;
  margin-left: 50px;
  font-size: 14px;
}

.last4 {
  margin-top: 50px;
  width: 25%;
}
/* .copy p{
  
    text-align: center;
    width: 100%;
  } */

@media (max-width: 1200px) {
  .last2,
  .last3 {
    font-size: 90%;
  }
}

@media (max-width: 769px) {
  .last1 {
    margin-left: 10%;
    width: 80%;
  }
  .last2,
  .last3 {
    width: 80%;

    margin-left: 10%;
  }
  .last4 {
    width: 90%;
    margin-left: 10%;
  }
  .last2,
  .last3,
  .last4 {
    margin-top: 15px;
  }
}

.p-link a {
  color: #29364a;
  text-decoration: none;
}

.last3 p {
  color: #29364a;
}
.last3 p:hover {
  color: #6543b1;
}

@media (max-width: 600px) {
}

.p-link a:hover {
  color: #6543b1;
}

.comingSoon {
  background-color: orange;
  color: white;
  border-radius: 20px;
  border: none;
  outline: none;
  padding: 2px 10px;
  margin-left: 3px;
  font-size: 12px;
}

/* Patch Component Start */

.patch1 {
  /* border: 1px solid #6543B1;
    background-color: #6543B1;
  padding: 28px;
  height: 180px;
  margin-top:200px; */
  /* margin-top: 15%; */
  position: relative;
}
.p-form {
  margin: 0 auto;
  text-align: center;
  background-color: #6543b1;
  color: white;
  /* padding: 2.5%; */
  padding-bottom: 3%;
}

.p-form p {
  margin-bottom: -1%;
  font-size: 15px;
}

@media (max-width: 501px) {
  .p-form h1 {
    font-size: 27px;
  }
  .p-form p {
    font-size: 18px;
  }
}

.patch-input {
  position: absolute;
  align-items: center;
  border-radius: 15px;
  z-index: 1;
  width: 200px;
  height: 22px;
  padding: 7px;
  padding-left: 15px;
  border: none;
  outline: none;
  left: 40%;
}
.patch-button {
  border: none;
  position: absolute;
  border-radius: 20px;
  z-index: 2;
  left: 53%;
  height: 36px;
  padding: 6px;
  width: 100px;
  background-color: #1e2c4a;
  color: white;
  outline: none;
}
@media (max-width: 769px) {
  .patch-input {
    margin-left: -22%;
    width: 60%;
  }
  .patch-button {
    margin-left: 10%;
  }
  .p-form {
    padding-bottom: 10%;
  }
}

/* Patch Comp End * */

/* Form comp starts */
.milestone {
  margin-left: 3%;
  width: 35%;
  z-index: -1;
  margin-top: 75px;
  height: 1%;
}

.form-pos {
  display: flex;
  justify-content: space-around;
  margin-top: 80px;
}

.forms {
  /* float:right; */
  /* width:45%; */
  padding-top: 25px;
  /* margin-top: -630px; */
}
.forms h1 {
  color: #042657;
  font-weight: 600;
  font-size: 25px !important;
}
.fill {
  width: 90%;
  padding: 12px 20px;

  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.input-names {
  color: rgb(80, 76, 76);
  font-size: 15px;
}

.otp {
  background-color: #6534b1;
  border: none;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-top: 20px;
  border-radius: 5px;
  outline: none;
}

/* form comp ends */

/* Cards1 component start  */

.card-place {
  margin-top: 100px;
  margin-left: 45px;
  margin-bottom: 350px;
  padding: 0px 12px;
}
.cards1hr {
  /* width: 6%;
      border :3px solid #6543B1;
      margin-right: 86%;
      margin-bottom: -30px; */
  margin-top: 50px;
  margin-bottom: 35px;
  border: 4px solid #6543b1;
  width: 6%;
  margin-left: 4%;
}

.card-head {
  /* margin-top:-200px; */
  color: #042657;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 25px;
}
.top {
  color: #6543b1;
  font-size: 40px;
  background-color: white;
  margin-top: 5%;
}
.down {
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  color: #29364a;
  font-size: 13px;
  margin-bottom: 30px;
}
.mid {
  background-color: white;
  color: #042657;
  font-weight: 500;
  font-size: 18px;
}

.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 28%;
  margin-left: 47px;
  text-align: center;
  float: left;
  background-color: white;
  height: 175px;
}

.cards:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.hn {
  width: 40%;
  margin-left: 28%;
  margin-top: 25px;
  /* margin-bottom: -15.50%; */
  margin-bottom: -7px;
  overflow: hidden;
}

@media (max-width: 769px) {
  .card-head {
    /* margin: 0 auto; */
    text-align: center;
    font-size: 150%;
    /* margin-top: -30%; */
    margin-bottom: 10%;
    margin-left: -8%;
  }
  .new3 {
    /* margin-left: 200px; */
    border: 6px solid #6543b1;
    width: 5%;
  }
  .cards {
    margin: 0 auto;
    /* height: */
    width: 90%;
    margin-bottom: 10%;
  }
  .cards1hr {
    margin: 0 auto;
    width: 6%;
    margin-top: 50px;
    margin-bottom: -50px;
  }
  .hn {
    margin: 0 auto;
    width: 90%;
    margin-left: 2%;
    /* margin-bottom: -16.50%; */
    margin-bottom: -7px;
  }
}
/* Card1 component Ends */

.primaryCol {
  color: #29364a;
}

.fainsta:hover {
  color: #6651b1 !important;
}

/* Intro comp start */

.intro {
  font-size: 55px;
  font-weight: 600;
  width: 100%;
  color: #042657;
}

@media (min-width: 1485px) {
  .intro {
    width: 80% !important;
  }
}

@media (max-width: 1100px) {
  .intro {
    font-size: 45px !important;
  }
}

@media (max-width: 890px) {
  .intro {
    font-size: 38px !important;
  }
}

.anytime {
  font-size: 130%;
  margin-bottom: 4%;
  margin-top: 10px;
  font-weight: 700;
  color: #fd581f;
}

.new5 {
  margin-top: -20px;
  border: 4px solid #6543b1;
  width: 7%;
  margin-left: 1px;
  background-color: #6543b1;
}
.introp {
  width: 60%;
  font-size: 25px;
  color: #29364a;
}
.intro-input {
  width: 33%;
  border: none;
  outline: none;
  border-bottom: 1px solid grey;
  padding: 10px;
  z-index: 1;
}
.arrow {
  margin-top: 16px;
  margin-left: -15px;
  border: solid #6543b1;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  z-index: 2;
  position: absolute;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.stories {
  color: #fd581f;
  /* font-size: 37 px; */
}
.Intro-div {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 7%;
  margin-top: 5%;
  display: flex;
}
.watch {
  width: 45%;
  /* float:right; */
  /* margin-right: 50px; */
}

.getstarted {
  display: block;
  color: white;
  background-color: #6543b1;
  padding: 14px 35px;
  text-decoration: none;
  border: none;
  border-radius: 5px;
}

.getstarted ::after {
  position: absolute;
  content: '';
  height: 2px;
  background-color: white;
  width: 30px;
  margin-left: 8px;
  margin-top: 0.65%;
}

.branding1 {
  text-align: center;
  margin-top: 5%;
  /* margin-bottom: -15px; */
  background-color: rgba(226, 224, 224, 0.09);
  padding-top: 2.5%;
}

h2 {
  font-size: 30px;
  font-weight: bold;
  color: #fd581f;
  margin-top: 50px;
  margin-bottom: -10px;
}

.br1 {
  width: 85%;
}

.br2 {
  width: 99%;
}

@media (max-width: 850px) {
  .Intro-div {
    margin-top: 10%;
  }
}

.bannerh2 {
  color: #fd581f;
  font-size: 27px !important;
  margin-top: 15px !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}

@media (max-width: 769px) {
  .Intro-div {
    /* overflow-x: hidden; */
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30%;
    /* margin-left: -7%; */
  }
  .intro {
    margin: 0 auto;
    font-size: 33px !important;
    text-align: center;
    width: 60%;
  }
  .anytime {
    margin: 0 auto;
    margin-top: 15px !important;
    text-align: center;
    font-size: 110%;
    font-weight: 400;
  }
  .new5 {
    margin: 0 auto;
    width: 13%;
    border: 4px solid #6543b1;
    margin-top: 5%;
  }
  .introp {
    margin: 0 auto;
    font-size: 120%;
    text-align: center;
    width: 80%;
    margin-top: 5%;
  }

  .getstarted {
    margin: 0 auto;
    margin-top: 5%;
  }
  .bannerh2 {
    font-size: 20px !important;
  }
}

@media (max-width: 550px) {
  .intro {
    width: 85%;
  }
  .bannerh2 {
    font-size: 17px !important;
  }
}

@media (max-width: 400px) {
  .intro {
    font-size: 30px !important;
    width: 100%;
  }
}

@media (max-width: 300px) {
  .intro {
    font-size: 27px !important;
  }
}

/* Buss Comp Start */

.bus-div {
  margin-top: 7%;
  /* margin-bottom: 45%; */
}

.bus {
  width: 22%;
  margin-top: -20px;
  margin-bottom: 25px;
  margin-left: 5%;
  color: #042657;
  font-weight: 600;
  font-size: 24px;
}

.new4 {
  margin-bottom: 35px;
  border: 3.5px solid #6543b1;
  width: 4%;
  margin-left: 5%;
  background-color: #6543b1;
}

.card-holder {
  margin-top: 4%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 992px) {
  .container {
    display: block;
  }
  .container-tablets-mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .container {
    display: none;
  }
  .container-tablets-mobile {
    display: block;
  }
}

.buss-cards {
  box-shadow: 0 4px 19px 0 rgb(0 0 0 / 6%);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  height: 330px;
}
.buss-cards img {
  width: 40px;
}
.buss-cards:hover img {
  width: 40px;
  height: 40px;
}

.cdrsec1 img {
  width: 24%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.cdrsec2 img {
  width: 24%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.crdsec1 {
  padding-top: 40px;
  padding-left: 25px;
  padding-right: 15px;
  height: 100%;
}
.crdsec1 img {
  margin-top: 20px;
}
.mobcrdsec1 {
  padding-top: 40px;
  padding-left: 25px;
  padding-right: 15px;
  height: 100%;
}

.mobcrdsec1 img {
  margin-bottom: 20px;
}

.mobcrdsec2 {
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  height: 100%;
}
.mobcrdsec2 li {
  margin-bottom: 5px;
  font-size: 13.3px;
}
.mobcrdsec2 p {
  color: white;
}

.crdsec2 {
  padding: 2.5rem;
}
.crdsec2 {
  height: 100%;
}
.crdsec2 li {
  margin-bottom: 5px;
}
.crdsec2 p {
  color: white;
}

.crdsec2 li {
  font-size: 13.3px;
}

.crdsec-heading {
  font-size: 24px;
  font-weight: 600;
  color: #042657;
  margin-top: 2%;
}

.crdsec-heading + p {
  color: #042657;
}
. {
  margin-top: 4%;
}
.buss-row div {
  margin-bottom: 13px;
}

/* // Extra small devices (portrait phones, less than 576px) */
/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
  .row {
    margin-left: 0px !important;
  }
  .crdsec-heading {
    font-size: 20px;
  }
  .crdsec-heading + p {
    font-size: 15px;
  }
}

/* // Medium devices (tablets, 768px and up) */

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .card1:hover > .crd1sec1 {
    display: none;
  }

  .card1:hover > .crd1sec2 {
    display: block;
  }

  .card2:hover > .crd2sec1 {
    display: none;
  }

  .card2:hover > .crd2sec2 {
    display: block;
  }

  .card3:hover > .crd3sec1 {
    display: none;
  }
  .card3:hover > .crd3sec2 {
    display: block;
  }

  .card4:hover > .crd4sec1 {
    display: none;
  }
  .card4:hover > .crd4sec2 {
    display: block;
  }

  .card5:hover > .crd5sec1 {
    display: none;
  }
  .card5:hover > .crd5sec2 {
    display: block;
  }

  .card6:hover > .crd6sec1 {
    display: none;
  }
  .card6:hover > .crd6sec2 {
    display: block;
  }
}

@media (max-width: 991px) {
}

/* // Extra large devices (large desktops, 1200px and up) */

/* .imgi{
   position: relative;
   width: 300px;
   height:300px;
   overflow:hidden;
 } */
/* .onee:hover{
   background-image: ('../images/onee.png');
 }
 .onee:hover{
 background-image: url('../images/a.png');
 } */
/* .card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
   transition: 0.3s; 
  width: 26%;
  background-color: white;
  height:200px;
  padding: 15px;
  margin-left: 60px;
  margin-top:50px;
  float:left;
  overflow: hidden;
  border-radius: 20px;
  color: #042657;
}

.card p{
  color:#29364A;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background-color:#6543B1;
  font-size: 0;

}


 .card:hover:after {
  content: attr(data-hover);
  font-size: 23px;
} 

 .headings{
  background-color: white;
  margin-left: 15px;
  margin-bottom:-4px;
  background-color: transparent;
}

.footings{
  background-color: white;
  margin-left: 15px;
  padding-bottom: 20px;
  background-color: transparent;
}

.topings{
  background-color: white;
  margin-left: 15px;
  margin-right: 300px;
  font-size: 35px ;
  background-color: transparent;
} */

@media (max-width: 769px) {
  .bus {
    text-align: center;
    margin: 0 auto;
    width: 80%;
    font-size: 20px;
    margin-top: 2%;
  }
  .buss-cards {
    margin: 0 auto;
    width: 80%;
  }
  .bus-div {
    margin-bottom: -45%;
  }
  .bus-div h1 {
    text-align: center;
    margin: 0 auto;
    width: 70%;
  }
  .new4 {
    /* width: 20%; */
  }
}
/* Buss Comp End  */

/* Sol component Start */

.sol-div {
  display: flex;
  margin-left: 5%;
  margin-top: 5%;
}

.blob {
  width: 20%;
  z-index: 5;
  position: absolute;
  margin-top: 8%;
}

.im {
  z-index: 10;
  margin-left: 40%;
  width: 70%;
  margin-top: 12%;
  height: 84%;
}

.getintouch {
  margin-top: 15%;
  width: 29%;
  margin-left: 5%;
  color: #042657;
}
.getinh {
  color: #042657;
  font-weight: 600;
  font-size: 25px;
}

.getinp {
  margin-top: 2%;
  color: #29364a;
  font-size: 15px;
}

.getinb {
  width: 170px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: #6543b1;
  color: white;
  outline: none;
}
.faded {
  color: #d2d4d6;
  font-size: 36px;
  width: 41%;
  margin-top: 8%;
  margin-left: 3%;
  line-height: 100%;
}

.rocket {
  /* float:right; */
  margin-right: 19%;
  margin-top: -16%;
  margin-left: 65%;
  width: 120px;
  height: 220px;
  position: absolute;
  z-index: 1;
  background-color: transparent;
}
.thread {
  margin-top: -9%;
  width: 34%;
  margin-left: 35%;
}

@media (max-width: 1231px) {
  .sol-div {
    display: none;
  }
  .rocket,
  .thread {
    display: none;
  }
}

@media (max-width: 1302px) and (min-width: 1230px) {
  .faded {
    font-size: 34px;
  }
}

@media (max-width: 1200px) {
  .watch {
    height: 65%;
  }
}

@media (min-width: 1500px) {
  .watch {
    height: 90% !important;
  }
}
@media (min-width: 1200px) {
  .watch {
    height: 80% !important;
  }
}

@media (max-width: 769px) {
  .watch {
    height: 35%;
    width: 85%;
  }
}

/* Sol component End */

/* How Component Start */

.new4h {
  margin-top: 45px;
  margin-bottom: 35px;
  border: 4px solid #6543b1;
  background-color: #6543b1;

  width: 4%;
  margin-left: 5%;
}
.how-div {
  padding-top: 6%;
  margin-top: 3%;
}

.how {
  margin-left: 5%;
  margin-top: -1%;
  color: #042657;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 25px;
}

.how-image {
  height: 44%;
  width: 44%;
  margin-left: 80px;
}
.how-list {
  margin-left: 59%;
  margin-top: -40%;
  margin-right: 12%;
}
.how-list li {
  font-size: 200%;
}
.howz {
  color: grey;
}
.howz:hover {
  color: #fd581f;
}
.how-p {
  margin-bottom: 40px;
  color: grey;
  font-size: 15px;
}
.how-h {
  font-size: 30px;
}

@media (max-width: 769px) {
  .new4 {
    margin: 0 auto;
    border: 4px solid #6543b1;
    background-color: #6543b1;
  }
  .how-div {
    margin-top: -1400px;
  }

  .how-h {
    font-size: 26px;
  }
  .how-p {
    margin-bottom: 30px;
  }
  .new4h {
    margin-top: 45px;
    /* position: absolute; */
    margin: 0 auto;
    border: 4px solid #6543b1;
    margin-top: 1450px;
    margin-left: 44%;
  }
  .howz {
    color: #fd581f;
  }
  .how {
    margin: 0 auto;
    text-align: center;
    margin-top: 10px;
  }
  /* .how-comb{
    flex-direction: column-reverse;
  } */
  .how-list {
    margin: 0 auto;
    width: 80%;
  }
  .how-image {
    margin: 0 auto;
    width: 80%;
    margin-left: 10%;
  }
}

@media (max-width: 500px) {
  .how-list li {
    font-size: 118%;
  }
  .how-h {
    font-size: 18px;
  }

  .how-p {
    margin-bottom: 25px;
    font-size: 13px;
  }
}

/* How component end */

/* Slider Component Start */
/* .slideit{
  margin-top:100px;
  width:100%
  
}
.slidez{
  z-index: -1;
}
.sliderimg{
width: 20%;
height: 20%;
  border-radius: 50%;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
 margin-left: 6%;
  object-fit: cover

}

.sl{
 
  margin-left: 35%;

}
.align{
  width: 80%;
  margin-left: -23%;
  text-align: center;
  color: #042657;
}





/* Slider component End */

.slideit {
  margin-top: 10%;
}
.sliderimg {
  margin-bottom: 20px;
}
.getinpp {
  margin-top: 50px;
}
.sl {
  width: 60%;
  text-align: center;
  margin: 0 auto;
  color: #042657;
}
.sl img {
  margin: 0 auto;
  border-radius: 50%;

  width: 20%;
  height: 20%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  margin-top: 10px;
}

.getstartedButton a:hover {
  text-decoration: none;
}

@media (max-width: 950px) {
  .sl img {
    width: 30%;
  }
}

@media (max-width: 769px) {
  .sl {
    width: 80%;
  }
  .sl img {
    width: 40%;
    height: 40%;
  }
  .slideit {
    margin-top: 15%;
  }

  .getstartedButton {
    text-align: center;
    margin-top: 20px;
  }
}

.pos h1 {
  color: #042657;
  font-size: 28px !important;
  font-weight: 600;
}

@media (max-width: 600px) {
  .pos h1 {
    font-size: 25px !important;
  }
}

.t-area {
  width: 95%;
  height: 120px;
  padding-top: 15px;
  padding-left: 12px;
  resize: none;
  outline: none;
  border-radius: 10px;
  border: 1px solid rgb(192, 188, 188);
}

@media (max-width: 850px) {
  .t-area {
    width: 90%;
  }
}

.t-area-button {
  background-color: #6534b1;
  border: none;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-top: 20px;
  border-radius: 5px;
  outline: none;
}

/* .line{
   
    text-align: center; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    width: 10%;
} */
ul.ks-cboxtags {
  list-style: none;
  padding: 20px;
}

.form-li {
  display: inline;
  list-style: none;
}

ul.ks-cboxtags li {
  display: inline;
  list-style: none;
  margin-right: 10px;
}

ul.ks-cboxtags li label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 500;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

.form-li label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 500;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.ks-cboxtags li label {
  padding: 13px 30px;
  margin-right: 3px;
  cursor: pointer;
}

.form-li label {
  padding: 13px 30px;
  margin-right: 3px;
  cursor: pointer;
}

ul.ks-cboxtags li input[type='checkbox']:checked + label {
  border: 2px solid #1bdbf8 !important;
  background-color: #00aab8 !important;
  color: #fff !important;
  transition: all 0.2s !important;
}

.form-li input[type='checkbox']:checked + label {
  /* border: 2px solid #1bdbf8 !important; */
  background-color: #00aab8 !important;
  color: #fff !important;
  transition: all 0.2s !important;
}

.form-li input[type='checkbox'] {
  display: absolute !important;
  opacity: 0 !important;
}

ul.ks-cboxtags li input[type='checkbox'] {
  display: absolute !important;
}

ul.ks-cboxtags li input[type='checkbox'] {
  position: absolute !important;
  opacity: 0 !important;
}

ul.ks-cboxtags li input[type='checkbox']:focus + label {
  border: 2px solid #e9a1ff !important;
}

.boxes {
  margin-top: -20px;
  margin-left: -15px;
}

.new3 {
  border: 4px solid #6543b1;
  background-color: #6543b1;
  margin: 0 auto im !important;
  width: 5%;
  margin-left: 40px;
}

/* AboutIntro sttart */

.ltu {
  background-color: white;
  border: 2px solid #6543b1;
  color: #6543b1;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-top: 20px;

  outline: none;
}

.sup {
  background-color: #6543b1;
  border: none;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-top: 20px;
  margin-right: 20px;
  outline: none;
}
.sup::after {
  position: absolute;
  content: '';
  height: 2px;
  background-color: white;
  width: 2%;
  margin-left: 8px;
  margin-top: 0.8%;
}
.ap {
  width: 100%;
  margin-left: 10%;
}

.ap1 {
  height: 100%;
  width: 96%;
  margin-left: -2%;
}

.various {
  text-align: center;
  /* margin-top: 5%; */
  padding-top: 2.5%;

  width: 100%;
  background-color: rgba(226, 224, 224, 0.09);
  /* margin-left: -6%; */
}

.various1 {
  padding-left: 0% !important;
  padding-top: 2.5%;
  width: 100%;
  background-color: rgba(226, 224, 224, 0.09);
  margin-top: 5%;
  /* margin-left: -6%; */
}

.various1 h3 {
  color: #fd581f;

  font-size: 20px !important;
}

.various img {
  width: 80%;
}

.various h3 {
  margin-top: 3%;
  color: #fd581f;
  font-size: 23px;
  font-weight: 500;
}

@media (max-width: 769px) {
  .ap {
    width: 65%;
    margin-left: 0;
  }
  .various {
    margin-top: -0.6%;
  }
  .various img {
    width: 90%;
  }
  .various h3 {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .various h3 {
    font-size: 15px;
  }
}

/* About intro ends */

.workit1 {
  display: flex;
  justify-content: center;
  margin-bottom: 8%;
}

.workit2 {
  display: flex;

  justify-content: center;
  margin-bottom: 8%;
}

.workit3 {
  display: flex;

  justify-content: center;
  margin-bottom: 8%;
}

.workit4 {
  display: flex;

  justify-content: center;
  margin-bottom: 8%;
}

.working-div h1 {
  text-transform: uppercase;
  color: #1e2c4a;
  font-weight: 700;
  margin-left: 40px;
}

.working {
  margin-top: 6%;
}

.working-mobile {
  display: none;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10%;
  text-align: center;
}

.workitsub-mobile {
  margin-top: 15px;
}

.workitsub-mobile p {
  margin-top: 7%;
  color: #042657;
  width: 100%;
}

.workitsub-mobile h2 {
  font-size: 23px;
  color: #042657;
  font-weight: 600;
}

@media (max-width: 769px) {
  .working {
    display: none;
  }
  .working-mobile {
    display: block;
  }
}

.workitsub p {
  margin-top: 7%;
  color: #042657;
  width: 60%;
}
.workitsub {
  padding: 50px;
  padding-top: 5%;
  width: 40%;
}
.worimg {
  width: 20%;
  height: 100%;
}

.workith2 {
  font-size: 25px;
  color: #042657;
  font-weight: 600;
}

@media (max-width: 1200px) and (min-width: 770px) {
  .workitsub {
    margin-top: 25px;
  }
  .workith2 {
    font-size: 22px !important;
  }
  .worimg {
    width: 22%;
  }
  .workitsub p {
    width: 85%;
  }
}

@media (max-width: 900px) {
  .workitsub p {
    font-size: 15px !important;
  }
}

@media (max-width: 1100px) {
  .worimg {
    margin-top: 2%;
  }
}

@media (max-width: 1000px) {
  .worimg {
    margin-top: 3%;
  }
}

@media (max-width: 951px) {
  .worimg {
    margin-top: 4%;
  }
}

@media (max-width: 901px) {
  .worimg {
    margin-top: 6%;
  }
}

@media (max-width: 851px) {
  .worimg {
    margin-top: 8%;
  }
}

@media (max-width: 769px) {
  .workitsub {
    width: 75%;
    margin: 0 auto;
    text-align: center;
  }
  .worimg {
    width: 30%;
  }
  .working-div h1 {
    margin: 0 auto;
    text-align: center;
  }

  .workit1 {
    padding: 3%;
  }
  .workit2 {
    padding: 3%;
  }
  .workit3 {
    padding: 3%;
  }
  .workit4 {
    padding: 3%;
  }
}

@media (max-width: 500px) {
  .worimg {
    margin-top: 10%;
  }
}

.handd {
  position: absolute;
  top: 45%;
  left: -3%;
}

.new-card-h h2 {
  /* margin-left:40px; */
  font-weight: 600;
  color: #042657;
  text-transform: uppercase;
}

.newcard {
  display: flex;
  justify-content: center;
  /* text-align: center; */
  margin-top: 5%;
}

.newcards {
  padding: 35px;
  padding-top: 45px;
  width: 25%;
  margin-right: 3%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.newcards p {
  margin-top: 15px !important;
  color: #1e2c4a;
}

.newcards h2 {
  color: #1e2c4a;
  font-weight: 600;
  margin-top: 0% !important;
  font-size: 22px !important;
}

.newcards:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

@media (max-width: 769px) {
  .newcards {
    width: 72%;
    margin-top: 4%;
    margin-right: 0%;
    padding: 25px !important;
  }
  .newcard {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    margin-top: 4%;
  }
  .new-card-h {
    margin: 0 auto;
    /* font-size: 15px; */
    text-align: center;
    /* margin-left: 6%; */
    font-weight: 500;
    /* margin-left: 40%; */
  }

  .handd {
    display: none;
  }
  .newcards h2 {
    font-size: 22px !important;
  }
  .newcards p {
    margin-top: 15px !important;
    font-size: 14px !important;
  }
}

/* .Findworkslider{
  margin-top:10%
}
.findworkslider{
  margin-top: 15%;
  margin-left: 15%;
  width: 40%;
}
.mr{
  width: 30%;
  margin-left:15%
} */

/* find Work Slider start */
.Findworkslider {
  margin-top: 35px;
}

.ro {
  display: flex;

  width: 60%;
  margin: 0 auto;
}
.ro img {
  width: 40%;
  height: 1%;
  margin-right: 10%;
}
.ro div {
  width: 50%;
  margin-top: 10vh;
}
.ro p {
  color: #fd581f;
  text-transform: capitalize;
  font-weight: 700;
  /* margin-bottom: -20%; */
}

.ro .head {
  margin: 30px 0px;
  font-size: 25px;
  text-transform: capitalize;
  color: #29364a !important;
  font-weight: 600;
}

.ro article {
  font-size: 20px;
  margin-top: -10%;
  margin-bottom: -8%;
  color: #29364a !important;
}

@media (max-width: 1053px) {
  .ro img {
    width: 43%;
    height: 40%;
    margin-top: 4%;
  }
}

@media (max-width: 769px) {
  .ro {
    flex-direction: column;
  }
  .ro img {
    width: 80%;
  }
  .ro div {
    width: 90%;
    margin-top: -8%;
    text-align: center;
  }
  .ro .head {
    font-size: 21px;
  }
}

/* Find work slider end */

.newbuss {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 25px !important;
}

.newbuss-cards {
  /* padding: 5% !important; */
  padding-left: 3% !important;
  padding-top: 3%;
  padding-bottom: 3%;
  display: flex;
  align-items: center;
  flex-direction: row !important;
  width: 30%;
  margin-top: 2%;
  margin-left: 2%;
  border: none !important;
  box-shadow: 0 4px 19px 0 rgb(0 0 0 / 6%);
}

.nbcim {
  width: 45px;
}

.nbch2 {
  font-size: 24px;
  font-weight: 600;
  color: #042657;
  margin-top: -5px;
  margin-left: 5%;
}

.con {
  margin-left: 20px;
}

@media (max-width: 950px) {
  .nbch2 {
    font-size: 22px;
  }
  .nbcim {
    width: 40px;
  }
}

@media (max-width: 769px) {
  .nbcim {
    width: 35px;
    height: 35px;
  }
  .newbuss {
    flex-direction: column;
    align-items: center;
    margin-top: 6%;
  }
  .newbuss-cards {
    width: 75%;
    margin-top: 5% !important;
  }
  .nbch2 {
    /* margin-top:-2px; */
  }
}

@media (max-width: 600px) {
  .nbch2 {
    font-size: 17px;
    margin-top: -3px;
  }
}

/* crises component start */

.crises {
  margin-top: 15%;
}

@media (max-width: 500px) {
  .crises {
    margin-top: 20%;
  }
}

@media (max-width: 300px) {
  .crises {
    margin-top: 25%;
  }
}

.yes {
  margin: 0 auto;
  text-align: center;
  font-weight: 600;
  font-size: 60px;
  color: #042657;
  /* width:60%; */
}

@media (max-width: 1140px) {
  .yes {
    font-size: 57px;
  }
}

@media (max-width: 1076px) {
  .yes {
    font-size: 55px;
  }
}

@media (max-width: 1040px) {
  .yes {
    font-size: 50px;
  }
}

@media (max-width: 715px) {
  .yes {
    font-size: 38px;
  }
}

@media (max-width: 550px) {
  .yes {
    font-size: 34px;
  }
}

@media (max-width: 450px) {
  .yes {
    font-size: 28px;
    /* width: 100%; */
  }
}
@media (max-width: 400px) {
  .yes {
    font-size: 25px;
  }
}

.mask {
  width: 25%;
  margin-left: 38%;
  margin-top: 4%;
}
.virus3 {
  width: 3%;
  position: absolute;
  opacity: 0.4;
  right: 60%;
  top: 55%;
}
.virus1 {
  width: 6%;
  position: absolute;
  right: 75%;
  top: 85%;
}
.virus2 {
  width: 5%;
  position: absolute;
  top: 85%;
  right: 22%;
}
@media (max-width: 769px) {
  .yes {
    width: 80%;
  }
  .virus3 {
    /* margin-top:; */
  }
  .virus2,
  .virus1 {
    margin-top: -25%;
    width: 5%;
  }
}

/* crises component ends */

.feat1 {
  display: flex;
  margin-bottom: 15%;
}
.feat1 p {
  width: 58%;
  margin-top: 25px;
  font-size: 18px;
  margin-right: 15%;
  color: #1e2c4a;
}
/* .feat1 p{
  font-size:25px;
  width:70%;
  margin-left:16%;
  margin-top:7%;
} */
.feat1 img {
  width: 20%;
  height: 1%;
  /* margin-right:15%; */
}

@media (max-width: 1122px) {
  .feat1 p {
    width: 50%;
    font-size: 16px;
  }
}

@media (max-width: 999px) {
  .feat1 img {
    width: 23%;
  }
}

@media (max-width: 900px) {
  .feat1 img {
    width: 26%;
  }
}

@media (max-width: 855px) {
  .feat1 img {
    width: 28%;
  }
}

@media (max-width: 796px) {
  .feat1 img {
    width: 30%;
  }
}

.feat2 {
  display: flex;
  margin-bottom: 20%;
}

.feat2 p {
  font-size: 25px;
  width: 70%;
  margin-left: 16%;
  margin-top: 7%;
  color: #1e2c4a;
}

.feat2 img {
  width: 50%;
  height: 1%;
}

.feat3 {
  display: flex;
  margin-bottom: 15%;
}

.feat3 img {
  width: 40%;
  height: 1%;
}

.features {
  margin-top: 10%;
  margin-right: 10%;
  margin-left: 15%;
}

.yfg {
  width: 23%;
  margin-left: 27%;
  margin-bottom: -120px;
  text-transform: uppercase;
  margin-top: -5%;
  color: #1e2c4a;
}

.yfg h2 {
  color: #042657;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 0px !important;
}

.yfg hr {
  width: 90%;
  margin-left: 0%;
  border: 2px solid #6543b1;
  background-color: #6543b1;
}

@media (max-width: 1245px) {
  .yfg {
    width: 25%;
    margin-bottom: -100px;
  }
}

@media (max-width: 1150px) {
  .yfg h2 {
    font-size: 22px;
  }
}

@media (max-width: 1075px) {
  .feat2 p {
    margin-left: 10%;
  }
}

@media (max-width: 1012px) {
  .yfg {
    width: 28%;
  }
}

@media (max-width: 962px) {
  .feat2 img {
    width: 47%;
  }
}

@media (max-width: 906px) {
  .yfg {
    width: 29%;
  }
}

@media (max-width: 874px) {
  .feat2 img {
    width: 45%;
  }
  .yfg h2 {
    font-size: 21px;
  }
}

@media (max-width: 836px) {
  .yfg {
    margin-bottom: -80px;
    width: 31%;
  }
}

@media (max-width: 782px) {
  .yfg h2 {
    width: 90%;
    font-size: 20px;
  }
}

.yfb {
  position: absolute;
  width: 20%;
  text-align: right;
  font-weight: lighter;
  margin-left: 35%;
  text-transform: uppercase;
  margin-top: -10%;
  color: #1e2c4a;
}

.yfb hr {
  width: 74%;
  margin-right: 0%;
  border: 2px solid #6543b1;
  background-color: #6534b1;
}

.yfb h2 {
  color: #042657;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 0px !important;
}

@media (max-width: 1360px) {
  .yfb hr {
    width: 80%;
  }
}

@media (max-width: 1250px) {
  .yfb hr {
    width: 90%;
  }
  .yfb h2 {
    font-size: 22px;
  }
}

@media (max-width: 1050px) {
  .feat3 {
    margin-top: 150px;
  }
}

@media (max-width: 950px) {
  .yfb hr {
    width: 100%;
  }
}

@media (max-width: 913px) {
  .yfb h2 {
    font-size: 21px;
  }
}

@media (max-width: 873px) {
  .yfb {
    width: 21%;
  }
}

@media (max-width: 832px) {
  .yfb {
    width: 22%;
  }
}

.yfblines {
  font-size: 20px;
  color: #042657;
  font-weight: 600;
}

@media (max-width: 838px) {
  .yfblines {
    font-size: 18px;
  }
}

@media (max-width: 796px) {
  .yfb h2 {
    font-size: 20px;
  }
}

/* @media(max-width:) */

.precautions {
  text-align: center;
}

.pi1 {
  height: 205px !important;
  width: 205px !important;
  margin-top: -14px;
}
.pi2 {
  height: 180px !important;
  width: 180px !important;
}

.times {
  width: 55%;
  color: #042657;
  font-size: 35px;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 1350px) {
  .times {
    font-size: 30px !important;
    width: 65%;
  }
}

@media (max-width: 1100px) {
  .times {
    font-size: 27px !important;
  }
}

@media (max-width: 950px) {
  .times {
    width: 68% !important;
    font-size: 26px !important;
  }
}

@media (max-width: 800px) {
  .times {
    font-size: 25px !important;
    width: 70% !important;
  }
}

@media (max-width: 700px) {
  .times {
    font-size: 24px !important;
    width: 77% !important;
  }
}

@media (max-width: 600px) {
  .times {
    font-size: 21px !important;
    width: 85% !important;
  }
}

@media (max-width: 500px) {
  .times {
    font-size: 20px !important;
    width: 98% !important;
  }
}

@media (max-width: 769px) {
  .feat1 {
    flex-direction: column;
    text-align: center;
  }
  .feat1 p {
    margin-right: -40%;
    /* margin-top: 30%; */
    width: 100%;
  }

  .feat1 img {
    width: 60%;
    margin: 0 auto;
  }
  .feat2 {
    flex-direction: column-reverse;
    margin-top: 10%;
  }
  .feat2 p {
    width: 100%;
  }
  .feat2 img {
    width: 100%;
  }
  .feat3 {
    margin-top: 115px !important;
    flex-direction: column;
  }
  .feat3 p {
    width: 100%;
  }
  .feat3 img {
    width: 100%;
  }
  .yfg {
    width: 75%;
    margin: 0 auto;
    text-align: center;
    /* margin-left: 15%; */
  }
  .yfg hr {
    margin: 0 auto;
    width: 40%;
    margin-top: 20px;
  }
  .yfb {
    width: 72%;
    margin: 0 auto;
    text-align: center;
  }
  .yfb hr {
    margin: 0 auto;
    width: 30%;
    margin-top: 20px;
  }
  .times {
    width: 80%;
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
  }
  .precautions img {
    width: 80%;
  }
}

/* about comp starts */

.about {
  overflow-x: hidden !important;
  max-width: 100%;
  margin-top: 10%;
}
.about h1 {
  text-align: center;
  font-size: 55px;
  width: 40%;
  margin: 0 auto;
  margin-top: 2%;
  color: #042657;
  font-weight: 600;
}

@media (max-width: 1450px) {
  .about h1 {
    font-size: 50px;
    font-weight: 700;
  }
}

@media (max-width: 1315px) {
  .about h1 {
    font-size: 45px;
  }
}

@media (max-width: 1000px) {
  .about {
    margin-top: 15%;
  }
}

@media (max-width: 769px) {
  .about {
    margin-top: 20%;
  }
  .about h1 {
    width: 70%;
    font-size: 30px;
  }
  .about hr {
    width: 10%;
    border: 4px solid #6543b1;
  }
}

/* about comp ends */

/* who comp starts */
.who {
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10%;
  overflow-x: hidden !important;
}

.whoc {
  width: 60%;
  margin-right: 10%;
}

.whoc h1 {
  font-size: 25px;
  font-weight: 600;
  color: #203b75;
}

.whoc p {
  font-size: 13px;
  line-height: 20px;
  color: #042657;
}

.who img {
  width: 80%;
  /* margin-top:25%; */
}

@media (max-width: 1150px) {
  .who {
    flex-direction: column;
  }
  .whoc {
    width: 100%;
    text-align: center;
  }
  .who img {
    width: 40%;
  }
  .who-y-img {
    text-align: center;
  }
}

@media (max-width: 769px) {
  .who {
    flex-direction: column;
  }
  .who img {
    width: 70%;
  }
  .whoc {
    width: 100%;
  }
  .card-place {
    margin-top: 8%;
  }
  .who-y-img {
    text-align: center;
  }
}

/* who comp ends */

/* happy comp starts */
.happy {
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2%;
}
.hap {
  margin: 7%;
  margin-top: 13%;
}
.hap p {
  /* margin-bottom:-3px; */
  color: #fd581f;
}
.hap h1 {
  font-weight: 600;
  font-size: 27px !important;
  color: #042657;
}
.all-brands {
  width: 47%;
  height: 1%;
}
.joy {
  width: 45%;
  margin-right: 10%;
  height: 1%;
  /* margin-right:15%; */
}
.m-happy {
  overflow-x: hidden !important;
}
@media (max-width: 769px) {
  /* happy comp ends */

  .m-happy {
    margin-top: 15%;
  }
  .happy {
    flex-direction: column-reverse;
    width: 100%;
    margin-left: 10%;
  }
  .hap {
    margin: auto 0;
    text-align: center;
  }
  .hap h1 {
    font-size: 25px;
  }

  .all-brands {
    width: 80%;
  }
  .joy {
    width: 70%;
    margin-right: 10%;
  }
}

.ladydiv {
  display: flex;
  margin-left: 5%;
}
.lady {
  width: 50%;
  margin-right: 4%;
}
.ladyform {
  margin-top: 8%;
}
.fillings {
  width: 150%;
  padding: 8px;
  border: 1px solid rgb(185, 184, 184);
  border-radius: 3px;
  outline: none;
  margin-bottom: 3%;
}
.input-names1 {
  color: rgb(80, 76, 76);
  font-size: 12px;
  margin-bottom: 4%;
}
.submiting {
  color: white;
  background-color: #6543b1;
  padding: 10px;
  outline: none;
  border: none;
  width: 30%;
  margin-top: 5%;
}

.branding {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

.divContainer {
  display: none; /* flex-wrap: wrap; */
}

.divRight {
  width: 50%;
}

@media (max-width: 769px) {
  .divRight {
    width: 100%;
  }
  .divRight div {
    text-align: center;
  }
}

.divLeft {
  width: 60%;
}

@media (max-width: 1200px) {
  /* .navbar{
   justify-content: flex-start ;
  } */
}

@media (max-width: 1096px) {
  /* .navbar{
    justify-content: left !important;
  } */
}

@media (max-width: 1120px) {
  .introp {
    font-size: 16px !important;
  }
  .watch {
    margin-top: 50px;
  }
}

@media (max-width: 1336px) {
  .cards {
    /* height:215px ; */
  }
  .card-place {
    margin-left: -5px;
  }
}

@media (max-width: 1068px) {
  .cards {
    height: 200px;
  }
}
/* @media(max-width:1030px){
  .cards{
    height:250px;
  }
} */

/* .cards1div{
width: 100%;

} */

@media (max-width: 1000px) {
  /* .card-place{
    margin-left:-10px;
  } */
  .cards {
    margin-left: 35px;
  }
}

@media (max-width: 769px) {
  .cards {
    height: 30%;
  }
}

.new4hn {
  margin-top: 50px;
  margin-bottom: 35px;
  border: 4px solid #6543b1;
  background-color: #6543b1;

  width: 4%;
  margin-left: 5%;
}

@media (max-width: 769px) {
  .new4hn {
    margin-top: 50px;
    margin: 0 auto;
    border: 4px solid #6543b1;
    margin-left: 47%;
  }
}

.hown {
  margin-left: 5%;
  margin-top: -1%;
  color: #042657;
  font-weight: 600;
  /* text-transform: uppercase; */
  font-size: 25px;
}

@media (max-width: 769px) {
  .hown {
    margin: 0 auto;
    text-align: center;
    margin-top: 5%;
  }
}

@media (max-width: 500px) {
  .card-place {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-left: -15px;
    margin-right: 15px;
  }
  .hn {
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media (max-width: 769px) and (min-width: 500px) {
  .fainsta {
    margin-top: 10px;
  }
}

@media (max-width: 1300px) and (min-width: 1100px) {
  .milestone {
    width: 30%;
    height: 80%;
    margin-top: 100px;
  }
}

@media (max-width: 1100px) {
  .milestone {
    width: 25%;
    height: 70%;
    margin-top: 150px;
  }
  .milestone {
    display: none;
  }
}

@media (max-width: 769px) {
  .milestone {
    display: none;
  }
}

@media (max-width: 400px) {
  .forms {
    margin-left: 3%;
  }
}

@media (max-width: 1096px) {
  .pos {
    margin-top: 30px;
  }
}

.formbtns {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
}

.firstTwo {
  display: flex;
}

@media (max-width: 850px) {
  .firstTwo {
    flex-direction: column;
  }
  .milestone {
    display: none;
  }
}

/* @media() */

@media (max-width: 769px) {
  .por {
    font-size: large !important;
  }
}

@media (max-width: 500px) {
  .card-place {
    margin-top: 6%;
  }
}

.icon-head {
  margin-bottom: 15px;
}

.fwintro {
  font-size: 55px;
  font-weight: 600;
  width: 75%;
  color: #042657;
}

.fwh4 {
  font-size: 25px;
  width: 70%;
  color: #042657;
}

@media (max-width: 1220px) {
  .fwh4 {
    font-size: 23px;
  }
}

@media (max-width: 1130px) {
  .fwintro {
    width: 75%;
  }
}

@media (max-width: 1037px) {
  .fwintro {
    width: 80%;
  }
}

@media (max-width: 973px) {
  .fwintro {
    width: 82%;
  }
}

@media (max-width: 951px) {
  .fwintro {
    width: 84%;
  }
}

@media (max-width: 930px) {
  .fwintro {
    width: 100%;
  }
}

@media (max-width: 783px) {
  .divLeft {
    width: 71%;
  }
}

@media (max-width: 769px) {
  .introContainer {
    display: none;
  }

  .divContainer {
    display: block;
    padding-top: 7%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20%;
  }
  .fwh4 {
    margin: 0 auto;
    text-align: center;
    width: 85%;
    margin-top: 5.7%;
  }

  .fwintro {
    margin: 0 auto;
    font-size: 210%;
    text-align: center;
    width: 80%;
  }
}

.fwsmintro {
  display: none;
  color: #042657;
  font-weight: 600;
}

@media (max-width: 550px) {
  .fwintro {
    display: none;
  }
  .fwsmintro {
    display: block;
    font-size: 24px;
    text-align: center;
  }
}

.introCol {
  padding: 5% 5% 0px 10% !important;
}

.introCol1 {
  padding-left: 5% !important;
}

.fw-row {
  margin-top: 8%;
}

@media (max-width: 600px) {
  .fw-row {
    margin-top: 12%;
  }
}

@media (max-width: 515px) {
}

@media (max-width: 1120px) {
  .fwh4 {
    font-size: 16px !important;
  }
}

.gplay {
  width: 18%;
  margin-top: 0%;
}

.fwhr {
  /* margin-bottom: 35px; */
  border: 3.5px solid #6543b1;
  width: 4%;
  margin-left: 6%;
  background-color: #6543b1;
}

.fwh2 {
  margin-top: 0% !important;
  font-size: 24px;
  font-weight: 600;
  color: #042657;
  margin-left: 6%;
}

.fwbuss {
  margin-top: -2%;
}

@media (max-width: 769px) {
  .fwhr {
    margin: 0 auto;
    width: 7%;
    border: 4px solid #6543b1;
    margin-top: 5%;
  }
  .fwh2 {
    text-align: center;
    margin: 0 auto;
    width: 60%;
    font-size: 20px;
    margin-top: 20px !important;
  }
  .gplay {
    width: 35%;
  }
}

.working-div {
  margin-top: 2%;
}

.por {
  font-weight: 600;
}

.align {
  margin-top: 4%;
}

@media (max-width: 769px) {
}

.fwhdiv {
  display: flex;
  flex-direction: column;
}

@media (max-width: 769px) {
  .fwslart {
    font-size: 16px !important;
    margin-bottom: -2% !important;
  }
}

@media (max-width: 500px) {
  .fwslart {
    font-size: 13px !important;
  }
}

.mheader {
  border-bottom: none !important;
}

.mfooter {
  border-top: none !important;
}

.modal-div1,
.modal-div2 {
  margin-left: 10%;
}

@media (max-width: 1700px) and (min-width: 769px) {
  .card-textLaptop {
    font-size: 15px;
  }
}

@media (max-width: 769px) and (min-width: 441px) {
  .card-textIpad {
    font-size: 15px;
  }
}

@media (max-width: 440px) {
  .card-textIphone {
    font-size: 15px;
  }
}

.onHover {
  display: none;
}

.about-div {
  margin-top: -20%;
  display: flex;
  justify-content: center;
}

.lap-tab-img {
  width: 100%;
  height: 1% !important;
  z-index: -1;
}

.about-div-mob {
  /* margin-top:-15%; */
  display: flex;
  justify-content: center;
  display: none;
}

.mob-img {
  width: 100%;
  height: 0% !important;
  z-index: -1;
}

@media (max-width: 1200px) {
  .lap-tab-img {
    width: 85%;
  }
}

@media (max-width: 769px) {
  .about-div {
    margin-top: -5%;
  }
}

@media (max-width: 500px) {
  .about-div {
    display: none;
  }
  .about-div-mob {
    display: block;
    /* margin-top:0%; */
  }
}

.modal-header {
  border-bottom: none !important;
}
.modal-footer {
  border-top: none !important;
}

.modal-body {
  max-height: 80vh !important;
}

.modal-dialog {
  max-width: 40% !important;
}

.modal-img {
  max-width: 85% !important;
}

.modal-btn {
  margin-top: 10px;
  color: white !important;
  padding: 7px !important;
  background-color: #6543b1;
  border: none;
  border-radius: 5px;
}
.modal-input {
  padding: 8px !important;
  border-radius: 3px;
}

.modal-info-sect p {
  color: #042657 !important;
}
.modal-info-sect label {
  color: #dcdcdc !important;
}

@media (max-width: 1100px) {
  .modal-dialog {
    max-width: 60% !important;
  }
}

@media (max-width: 900px) {
  .modal-img-sect {
    display: none !important;
  }
}

.error-modal-sect div {
  text-align: center;
}

.form-completed-modal-sect {
  text-align: center;
}

.form-completed-modal-sect h2 {
  color: #042657;
  font-weight: 600;
  font-size: 20px !important;
}
.form-completed-modal-sect p {
  font-size: 14px !important;
}

.form-completed-modal-sect-div {
  line-height: 5px;
}

@media (max-width: 500px) {
  .form-completed-modal-sect h2 {
    font-size: 17px !important;
  }
  .form-completed-modal-sect p {
    font-size: 11px !important;
  }
}

@media (max-width: 769px) {
  .modal-dialog {
    display: flex !important;
    margin: 0 auto !important;
    max-width: 75% !important;
  }
  .error-modal-sect {
    flex-direction: column;
    text-align: center;
  }
}

.error-modal-sect1 {
  text-align: center;
}

/* .error-modal-sect-div{
  text-align: center;
} */

/* @media(max-width:) */

.modal-first-form-complete h2 {
  color: #042657;
  font-weight: 600;
}

.about-us-banner {
  background-color: #f4f8fc;
  text-align: center;
  padding: 4%;
  margin-top: 7%;
  margin-bottom: -10px;
  color: #042657;
  overflow-x: hidden !important;
}

.about-us-banner-header p {
  font-size: 28px;
  font-weight: 600;
}

@media (max-width: 600px) {
  .about-us-banner-header p {
    font-size: 20px;
  }
}

@media (max-width: 1100px) {
  .about-us-banner-content {
    font-size: 16px;
  }
  .about-us-banner {
    margin-bottom: 0;
  }
}

@media (max-width: 769px) {
  .about-us-banner-content {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .about-us-banner-content {
    font-size: 11px;
  }
}

.buss-cards ul {
  margin-left: -20px;
}

.buss-cards button {
  background-color: transparent;
  outline: none;
  border: none;
}

.Last a:hover {
  text-decoration: none;
}

.half-width .first-p {
  margin-top: 5px !important;
}

.fwiconclass {
  margin-top: 5%;
}

.Last:after {
  content: ' ';
  display: block;
  clear: both;
}

.fa {
  background-color: #fd581f !important;
  border-radius: 50% !important;
  padding: 3px !important;
}

.fa-arrow-right {
  color: white;
  font-size: 10px;
}

.covidlistitem {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 600;
}

.covidlistitem i {
  margin-right: 10px;
}

@media (max-width: 853px) {
  .covidlistitem {
    font-size: 20px;
  }
}

@media (max-width: 789px) {
  .covidlistitem {
    font-size: 19px;
  }
}

.feat3 li {
  list-style: none;
  display: flex;
  align-items: center;
  /* line-height: 0px; */
}

.feat3 i {
  margin-right: 5px;
}

@media (max-width: 769px) {
  .covidlistitemspar {
    /* margin-left: 20px; */
  }
}

.feat3 ul {
  height: 100%;
  margin-top: 25%;
  margin-left: -20px;
}

.navbar-header-mobile > div {
  position: absolute;
  display: block;
  top: 100%;
  transform: translateX(-100%);
  transition: 0.3s;
}

.navbar-header-mobile > div > ul {
  background-color: #6543b1;
  color: white;
}

.navbar-header-mobile > div li {
  list-style: none;
}

.right-arrow,
.left-arrow {
  color: #6543b1;
  background-color: transparent !important;
  font-size: 20px !important;
}
.left-arrow {
  color: white;
}

.icon-head {
  margin-top: 0px !important;
}
.mobcrdsec2 button {
  float: right !important;
}

.icons svg {
  font-size: 110%;
}

@media (max-width: 1100px) {
  .icons svg {
    font-size: 90%;
  }
}

.last4 a:hover {
  text-decoration: none;
  outline: none;
}

.covidmob {
  display: none;
  width: 100% !important;
}

@media (max-width: 600px) {
  .covidmob {
    display: block;
  }
  .covidweb {
    display: none;
  }
}

::placeholder {
  color: lightgrey;
  font-size: 12px;
}

.page-not-found-div {
  display: flex !important;
  justify-content: center !important;
}
.page-not-found-div a:hover {
  text-decoration: none;
}

.first {
  width: 95%;
}
.second {
  width: 100%;
}

@media (max-width: 850px) {
  .first {
    width: 100%;
  }
  .second-sect {
    margin-top: 18px;
  }
}

.error-p {
  font-size: 12px;
}

.boxtags-div {
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .pos {
    margin-left: 0px;
  }
}

.privacy-policy-heading,
.terms-heading {
  text-align: center;
  color: #042657;
  font-size: 30px;
  font-weight: 600;
}

@media (max-width: 500px) {
  .privacy-policy-heading {
    font-size: 19px;
  }
  .privacy-text,
  .terms-text {
    font-size: 12px;
  }
}

.privacy-policy {
  justify-content: center;
}

@media (max-width: 500px) {
  .mob-form {
    margin-left: 10px;
  }
  .mob-form-second-section {
    margin-top: 20px;
  }
}

.category-col {
  margin-left: 0px;
}

.category-btn {
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  padding: 13px;
  padding-left: 30px;
  padding-right: 30px;
}

.category-btn-2 {
  margin-left: 10px;
}

.clicked-category-btn {
  border: 2px solid #1bdbf8 !important;
  background-color: #00aab8 !important;
  color: #fff !important;
  transition: all 0.6s !important;
  border-radius: 25px;
  padding: 13px;
  padding-left: 30px;
  padding-right: 30px;
}

.categories-mob {
  display: none;
}

@media (max-width: 600px) {
  .categories {
    display: none;
  }
  .categories-mob {
    display: block !important;
  }
}

.logo {
  color: #042657;
  font-weight: bold;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  text-decoration: none !important;
}

.logo:link {
  text-decoration: none !important;
}
.logo:visited {
  text-decoration: none !important;
}
.logo:hover {
  text-decoration: none !important;
}
.logo:active {
  text-decoration: none !important;
}

.wrapper{
    margin-top: 5%;
    margin-bottom: 8%;
}

.tab-buttons-container{
    display: flex;
    justify-content: center;
    margin-top: 12% ;
}


.tab-buttons-selected{
   color:white;
   background: #6543B1;
   border: none;
   padding: 15px 35px;
   border-radius: 5px;
   font-weight: 500;
   font-size: 16px;
}

.tab-buttons{
    color:black;
    background: white;
    border: none;
    padding: 15px 35px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
}





/* .tab-buttons:last-of-type{
    margin-right: 0px;
} */
 
.tab-buttons-selected:active, .tab-buttons-selected:focus, .tab-buttons-selected:active{
    border: none;
    outline: none !important;
}

.tab-buttons-items{
    box-shadow: 2px 4px 10px 3px #cacaca;
    border-radius: 10px;
}

.tab-container{
    margin: 0 auto;
}

.contactUs-row{
width: 50%;
}

.inner-row{
display: flex;
    justify-content: center;
    margin-top: 12% ;    

    box-shadow: 2px 4px 10px 3px #cacaca;
    border-radius: 10px;
}


*:focus {
    outline: none;
}

.formContainer{
    margin: 0 auto;
    justify-content: center;
    padding: 40px;
    min-height: 200px;
    width: 35%;
    margin-top: 50px;
    border-radius: 10px;
    background: #F4F8FC;
}

.support-text{
    justify-content: center;
    width: 40%;
    margin: 0 auto;
    padding-top: 20px;
}

.graytext{
    color: rgb(167, 167, 167);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
}

.half-width{
    width: 50% !important;
    line-height: 0;
}

.full-width{
    width: -webkit-fill-available;
}

label{
    color:#757E8F;
    font-size: 14px;
    font-weight:550;
}

.input-container{
    width: -webkit-fill-available;
}

.input-container input{
    border: 1px solid  #f4f8fcb6;
    border-radius: 5px;
    padding: 12px 20px;
    color: #8d8d8d;
    width: -webkit-fill-available;
}

.input-container textarea{
    border: 1px solid  #f4f8fcb6;
    border-radius: 5px;
    padding: 12px 20px;
    color: #8d8d8d;
    width: -webkit-fill-available;
}

.youthButton{
    background: #6543B1;
    border: none;
    color: white;
    text-align: left;
    position: relative;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 1.1rem;
    /* text-transform: uppercase; */
}

.youthButton_or:hover{
    /* box-shadow: 2px 4px 10px 3px #cacaca; */
}

.youth_button_line_or{
    position: absolute;
    right: 0px;
    color: #6543B1;
    top: 30%;
    width: 20px;
    height: 2px;
    background: #6543B1;
    border: none;
}

.youthButton_or{
    background: white;
    border: none;
    color: #6543B1;
    text-align: left;
    position: relative;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 1.1rem;
    text-transform: uppercase;
}

.youthButton:hover{
    box-shadow: 2px 4px 10px 3px #cacaca;
}

.faqs{
    margin-top: 100px;
    text-align: center;
}

.faqdata{
    width: 60%;
    justify-content: center;
    margin: 0 auto;
}

.faqdata .faq-body .faq-row .row-content-text {
    text-align: left !important;
}

.faqdata .faq-body .faq-row .row-content-text li {
    list-style: none;
}

.faqdata .faq-body .faq-row .row-title {
    text-align: left !important;
}




@media(max-width:1100px){
 .tab-buttons{
     padding: 10px 20px !important;
     font-size: 13px;
 }
 .tab-buttons-selected{
     padding: 10px 20px !important;
     font-size: 13px;
 }
 .tab-buttons-container{
     margin-top:15% !important;
 }
}


@media(max-width:900px){
    .tab-buttons-container{
        margin-top:17% !important; 
    }
    .tab-buttons{
        padding:8px 17px !important;
    }
    .tab-buttons-selected{
        padding: 8px 17px !important;
    }
}



@media(max-width:769px){
    .tab-buttons-container{
        margin-top:18% !important;
    }
    .tab-buttons{
       padding: 8px 14px !important;
    }
    .tab-buttons-selected{
       padding: 8px 14px !important;
    }
}

@media(max-width:500px){
    .tab-buttons-items{
        /* flex-direction:column !important; */
    display: flex !important;
    flex-direction: column !important;
    }
    .tab-buttons-container{
                margin-top:24% !important;

    }
    .tab-buttons{
        padding: 10px 10px !important;
        font-weight: 600;

    }
    .tab-buttons-selected{
        padding: 10px 10px !important;
font-weight: 600;

    }
}

/* @media(max-width:400px){
    .tab-buttons{
        padding: 10px 8px !important;
            font-size: 11px;
 font-weight: 600;
    }
    .tab-buttons-selected{
        padding: 10px 8px !important;
            font-size: 11px;
 
    }
} */


.first-p{
    padding-right: 20px;
}

.form-div{
 display: flex;    
}

@media(max-width:1000px){
    .formContainer{
        width: 75%;
    }
}

@media(max-width:501px){

    .first-p{
        padding-right: 0%;
        margin-bottom: 7%;
    }
}

@media(max-width:500px){
    .formContainer{
        width: 95%;
    }
    .form-div{
        flex-direction: column;
        justify-content: space-around;
    }
    .half-width{
            width: 100% !important;
    }
    .form-div input{
        width:-webkit-fill-available;
    }
}



@media(max-width:400px){
    .formContainer{
        width: 98%;
    }
}


